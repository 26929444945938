import styled from 'styled-components';

import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  SkeletonImage as SkeletonImageComponent,
  SkeletonHeadline as SkeletonHeadlineComponent,
} from '@xing-com/skeleton';
import { space3XL, spaceL, spaceM, spaceXXL } from '@xing-com/tokens';

export const LoadingStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space3XL};

  @media ${mediaSmallOrTiny} {
    & > :not(:first-child) {
      display: none;
    }
  }
`;

export const ArticleCardSkeletonContainer = styled.div`
  display: flex;
  gap: ${spaceXXL};
  margin: ${spaceM} 0;

  @media ${mediaSmallOrTiny} {
    flex-direction: column-reverse;
    gap: ${spaceL};
  }
`;

export const SkeletonTextContainer = styled.div`
  flex: 1 1 100%;
`;

export const SkeletonHeadline = styled(SkeletonHeadlineComponent)`
  margin-bottom: ${spaceM};

  @media ${mediaConfined} {
    margin-bottom: 0;

    & :not(:first-child) {
      display: none;
    }
  }
`;

export const SkeletonImageContainer = styled.div`
  flex: 0 0 300px;

  @media ${mediaSmallOrTiny} {
    flex: 0 1 100%;
  }
`;

export const SkeletonImage = styled(SkeletonImageComponent)`
  aspect-ratio: 16/9;
`;
