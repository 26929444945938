import { SkeletonBodyCopy } from '@xing-com/skeleton';

import {
  LoadingStateContainer,
  ArticleCardSkeletonContainer,
  SkeletonImageContainer,
  SkeletonTextContainer,
  SkeletonImage,
  SkeletonHeadline,
} from './loading-state.styles';

const ArticleCardSkeleton = () => (
  <ArticleCardSkeletonContainer>
    <SkeletonTextContainer>
      <SkeletonHeadline alignment="left" amountRows={2} size="small" />
      <SkeletonBodyCopy alignment="left" amountRows={4} />
    </SkeletonTextContainer>
    <SkeletonImageContainer>
      <SkeletonImage />
    </SkeletonImageContainer>
  </ArticleCardSkeletonContainer>
);

export const LoadingState: React.FC = () => {
  return (
    <LoadingStateContainer>
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
    </LoadingStateContainer>
  );
};
